export const SIGNALING_SERVER: string = 'wss://webrtc.promiseq.com'
export const ICE_SERVERS: RTCIceServer[] = [
  { urls: 'stun:stun.l.google.com:19302' },
  {
    urls: ['turn:51.116.106.239:3478'],
    username: 'promiseq',
    credential: 'Monitor123'
  },
  {
    urls: ['turn:3.76.17.192:5349'],
    username: 'thiesh',
    credential: 'Monitor123'
  }
]
export const WS_HELLO: string = 'HELLO'
export const WS_RECORDING_REQUEST: string = 'RECORDING_REQUEST'
export const WS_SESSION_OK: string = 'SESSION_OK'
export const WS_OFFER_REQUEST: string = 'OFFER_REQUEST'
export const WS_STREAM_UNAVAILABLE: string = 'STREAM_UNAVAILABLE'
export const WS_SEEK_EXCEEDED_FILE_DURATION: string =
  'SEEK_EXCEEDED_FILE_DURATION'
export const WS_SEEK_COMPLETED: string = 'SEEK_COMPLETED'

export const PROMISEQUBE_VIDEO_DURATION: number = 1200
export const PROMISEQUBE_SEEK_ENABLED: boolean = true

export const DEFAULT_QUOTA: number = 1000
export const DEFAULT_QUOTA_ALERT_PERCENTAGE: number = 70

export const DISARMED_TIME_RANGE = 'Disarmed Time Range'

export enum LiveStreamQualityType {
  LOW = 'low',
  HIGH = 'high'
}

export enum StreamMode {
  LIVE = 'live',
  RECORDING = 'recording',
  UNAVAILABLE = 'unavailable'
}

// Close event code list : https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
export enum WebSocketCloseEventCode {
  CLOSED_FOR_RESTART = 4040, // Custom code for auto-restart
  ABNORMAL_CLOSURE = 1006,
  NORMAL_CLOSURE = 1000,
  INTERNAL_ERROR = 1011
}
export const MAX_INITIAL_SIGNALING_SERVER_RESTART_ATTEMPTS = 5
export const MAX_ONGOING_SIGNALING_SERVER_RESTART_ATTEMPTS = 30

export enum ChartDataStatus {
  LOADING = 'Loading',
  READY = 'Ready',
  ERROR = 'Error'
}
